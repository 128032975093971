@forward '../../scss/style';
.TitleSection
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transform: translateY(-5rem);
}
.AbstractHolder
{
    width: 40rem;
    height: 40rem;
}
.Abstract
{
    width: 40rem;
    height: 40rem;
    position: relative;

}
.A-Left
{
    transform: translate(-3rem,-12rem)
}
.A-Right
{
    transform: translate(3rem,-12rem)
}
.firstTitle
{
    font-size: larger;
    padding-bottom: 1rem;
    color: var(--LightOrange);
}
.secondTitle
{
    font-size: x-large;
}
.MembersSection
{   transform: translateY(-22rem);
    display: flex;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 10rem;
    justify-content: space-evenly;
     align-items: center;
    justify-content: space-evenly;
}
.MemberCard
{
    height: 26rem;
    width: 18rem;
    background-color: var(--SecBackground);
    transition: all 300ms ease-in-out;
}
.mouse-parallax-container
{
    height: 50rem;
}
.MemberCard:hover
{
    scale: 1.05;
    box-shadow: 2px 22px 17px -7px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 22px 17px -7px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 22px 17px -7px rgba(0,0,0,0.75);
}
.memberImage
{
    width: 100%;
    height: 18rem;
}
.memberName
{   padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.memberTitle
{   
    
    padding-left: 1rem;
    padding-right: 1rem;
}
.memberSocials
{   width: 100%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding-top: 1rem;
    height: fit-content;
}
.memberSocial
{
    height: 1.5rem;
    width: 1.5rem;
    color: var(--LightOrange);
    border: solid 2px rgba(248, 248, 248, 0);
    padding: 0.4rem;
    border-radius: 3rem ;
    transition: all 300ms ease-in-out;
}
.memberSocial:hover
{   
    color: white;
    border: solid 2px white;
}

@media only screen and (max-width: 600px) 
{
    .TitleSection
    {
        justify-content: center;
    }
    .firstTitle
    {
        width: 100%;
    }
    .mouse-parallax-container
{
    height: 110rem;
}
.NavItem
{
    display: none;
}
.MembersSection
{ 
    flex-direction: column;
}
.MemberCard
{
    margin-top: 3rem;
}
.AbstractHolder
{
    display: none;
}
.Abstract
{
display: none;

}
.TitleSection
{
    padding-top: 12rem;
    padding-bottom: 12rem;
}
.A-Left
{
    transform: translate(-1rem,-1rem)
}
.A-Right
{
    transform: translate(1rem,-1rem)
}

}