@forward '../../scss/style';
.ContactForm{
    padding-top: 4rem;
    padding-bottom: 2rem;
}
.ContactForm form
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ContactForm label
{
    padding: 1rem;
    text-align: left;
    width: 42%;
    
}
.ContactForm input
{
    margin-top: 0rem;
    
}
.Title
{
    margin-bottom: 3rem;
}
.message
{
    width: 40%;
    height: 10rem;
    background-color: var(--SecBackground);
    border-color: var(--Orange);
    border-radius: 0.5rem;
    margin-bottom: 4rem;
    padding: 0.5rem;
    resize: none;
    color: white;
}
.textField
{
    width: 40%;
    padding: 0.5rem;
    background-color: var(--SecBackground);
    border-color: var(--Orange);
    border-radius: 0.2rem;
    margin-bottom: 2rem;
    color: white;
}
.btn
{
    margin-bottom: 2rem;
}
@media only screen and (max-width: 600px) 
{
    .textField
{
    width: 90%;

}
.message
{
    width: 90%;

}
.ContactForm label
{

    align-self: flex-start;
}
}