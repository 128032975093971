@forward '../../scss/style';

.AboutUsSec
{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 6.5rem;
    
}
.Section
{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    height: 30rem;
}
.AbstractHolder
{
    width: 40rem;
    height: 40rem;
}
.Abstract
{
    width: 40rem;
    height: 40rem;
    position: relative;

}
.A-Left
{
    transform: translate(-3rem,-12rem)
}
.A-Right
{
    transform: translate(3rem,-12rem)
}
.Logo
{
    width: 15rem;
    height: 15rem;
}
.AboutText
{
    padding-left: 20rem;
    padding-right: 20rem;
    text-align: center;
    transform: translateY(-10rem);
}
.AboutText p
{font-weight: 300;
    line-height: 1.5rem;
}
@media only screen and (max-width: 600px) 
{.AboutUsSec
{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 4rem;
    
}
    .Section
{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    height: 18rem;
}

.AbstractHolder
{
    width: 10rem;
    height: auto;
}
.Abstract
{
    width: 10rem;
    height: auto;
    position: relative;

}
.Logo
{
    width: 9rem;
    height: auto;
}
.A-Left
{
    transform: translate(-1rem,-1rem)
}
.A-Right
{
    transform: translate(1rem,-1rem)
}
.AboutText
{
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;

}
.AboutText p
{
    line-height: 1.2rem;
    font-size: 0.7rem;
    font-weight: 300;
}
}