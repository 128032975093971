@forward '../../scss/style';
.Technologies
{   width: 100%;
    padding-top:10rem;
    padding-bottom: 10rem;
    background-color: var(--SecBackground);
}

.Title
{
    text-align: center;
    margin-bottom: 10rem;
}
.Container
{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;

}
@media only screen and (max-width: 600px) {
 .Container
 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    
 }
 
 .techLogo
{
    margin-top: 3rem;
    width: 4rem;
    height: 10rem;
}
.Title
{
    margin-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
}
.techLogo
{
    width: 9rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

}
.techLogo img
{
    transition: all 300ms ease-in-out;
}
.techTitle
{
    margin-top: 3rem;
    justify-self: flex-end;
    transition: all 300ms ease-in-out;

}
.techLogo:hover img
{
    transform: translateY(-1rem);
    scale: 1.1;
}

.techLogo:hover .techTitle
{
    color: var(--Orange);
}