@forward '../../scss/style';
.Hero
{
    height: 55rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10rem;
    
}
.contained-r
{
    width: 50rem;
}

.HeroSegment
{   
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.heroText
{
    line-height: 2rem;
    font-size: 1.2rem;
}
.heroTitle
{
    width: 40rem;
    text-align: left;
    padding-bottom: 2rem;
    margin-left: 4rem;
    font-size: 1.7rem;
    padding-bottom: 10rem;

}

.line{
    margin-top: 2rem;
    width: 5rem;
    height: 0.5rem;
    display: block;
    background-color: orange;
}

@media only screen and (max-width: 600px) {
 .heroTitle
{
    width: 100%;
    padding-bottom: 2rem;

}
.Hero
{
    flex-direction: column;
}
.HeroSegment
{
    width: 100%;
}
}