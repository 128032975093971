@forward '../../scss/style';
.MotherNav
{
    width: 70%;
  
}
.hamburger
{
    display: none;
}
.Navbar{
padding-top: 1rem;
padding-bottom: 1rem;
     display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-bottom: 1rem;
    width: 100%;
    overflow: hidden;
    position: absolute;
    background-color: var(--Background);
    z-index: 2;
    box-shadow: 1px 14px 45px 10px rgba(0,0,0,0.68);
-webkit-box-shadow: 1px 14px 45px 10px rgba(0,0,0,0.68);
-moz-box-shadow: 1px 14px 45px 10px rgba(0,0,0,0.68);
}
li{
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
}
.navbarLogo
{
    height: 4rem;
    transition: all 300ms ease-in-out;
}
.navbarLogo:hover
{
    transform: translateY(-0.5rem);
    
}
.NavItem a
{
    color: white;
    font-weight: 700;
    border-bottom: solid rgba(255, 0, 0, 0) 2px;
    transition: all 300ms ease-in-out;
    
}
.NavItem a:hover
{
    color: var(--LightOrange);
    border-bottom: solid var(--LightOrange) 2px;
    
}
.NavItem .active
{
  color: var(--LightOrange);

}
@media only screen and (max-width: 600px) {
    .mobileNavItem a
    {
        display: block;
        color: var(--Orange);
       z-index: 10;
       padding-top: 1rem;
        
    }
    .Xbutton
    {
        margin-top: 1rem;
    }
 .empty
    {
        background-color: rgba(255, 222, 173, 0)!important;
    }
    .mobileSideMenu
    {
        width: 50%;
        display: flex;
        flex-direction: column;
        background-color: var(--SecBackground);
    }
    .mobileMenu
    {   
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;        
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        
    }
  .hamburger
  {
    display: block;
  }
    .navItem
  {
    display: none;
  }
  .Navbar
  {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbarLogoHolder
  {
    display: block !important;
  }
  .navbarLogo
  {
    height: 2rem;
  }
  .hamburger
{
    width: 2rem;
    height: 2rem;
    color: var(--Orange);
}
}

