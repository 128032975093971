
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
 
}

::-webkit-scrollbar {
  width: 0px;
}

html, body {
  height: 100%;
  overflow-x: hidden;
  background-color: var(--Background);
}


body {
  
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  color: white;
   width: 100%;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
  font-size: inherit;
}
p
{
  font-size: large;
}
a,a:visited,a:active,li
{
    text-decoration: none;
    color: inherit;
    list-style-type: none;
    
}
.contained-l
{
  margin-left: 4rem;
}
.contained-r
{
  margin-right: 4rem;
}
.contained
{
  margin-left: 4rem;
  margin-right: 4rem;
}
@media only screen and (max-width: 600px) {
  p{font-size: small;}
  .Title
  {
    font-size: large;
  }
 .contained-l
 {
    margin-left: 1rem;
 } 
.contained-r
{
    margin-right: 1rem;
}
.contained
{
    margin-left: 0;
    margin-right: 0;
}
}
.Title
{
  font-size: x-large;
}
#root, #__next {
  isolation: isolate;
}
.hidden
{
 opacity: 0;
}
.show
{
  opacity: 1;
}

.btn
{
    width: 13rem;
    background-color: var(--SecBackground );
    padding: 0.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: solid var(--Orange) 3px;
    color: var(--Orange);
    border-radius: 3rem;
    transition: all 300ms ease-in-out;
}
.btn:hover
{
    background-color: var(--Background);
}
select
{
  background-color: rgba(240, 248, 255, 0);
  border-color: var(--Orange);
  padding: 0.5rem;
  width: 12rem;
  border-radius: 3rem;
  color: var(--Orange);
}