@forward '../../scss/style';

.Footer
{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 20rem;
    background-color: var(--SecBackground);
    align-items: center;
    justify-content: space-between;
      -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.footerLogo
{
    width: 10rem;
    transition: all 300ms ease-in-out;
}
.footerLogo:hover
{
    scale: 1.1;
    
}

.copyrights
{
    width: 10rem;
    text-align: center;
    font-size: small;
}
.footerTitle
{
    font-size: larger;
    color: var(--Orange);
    margin-bottom: 1rem;
}
.footerText
{
    font-size: medium;
}
.Box
{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 30rem;
    padding-left: 4rem;
    padding-right: 4rem;
}
.Socials
{
    margin-bottom: 1rem;
}
.socialIcon
{   
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;
    border: solid white 2px;
    border-radius: 3rem;
    transition: all 300ms ease-in-out;
}
.socialIcon:hover
{   
    background-color: var(--Orange);
    border: solid var(--Orange) 2px;
    cursor: pointer;
}
.contactSocials
{   width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}
.footerText
{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    transition: all 300ms ease-in-out;
}
.footerText:hover
{
    color: var(--Orange);
    cursor: pointer;
}
.footerNav a
{
    text-align: right;
}
.footerNavItem
{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: all 300ms ease-in-out;
}
.footerNavItem:hover
{
    color: var(--Orange);
}

.footerNavItem a
{
    text-align: right;
}
.right-Box
{
    align-items: flex-end;
    text-align: right;
}
@media only screen and (max-width: 600px) {
  .Footer
  {
    flex-direction: column-reverse;
    height: fit-content;
  }
  .left-Box
  {
    align-items: center;
    margin-bottom: 4rem;
  }
  .right-Box
  {
    display: none;
  }
  .Box
  {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}